<template>
  <b-card no-body class="p-1" ref="userAccounts">
    <b-overlay
      :show="state.loading"
      rounded="sm"
    >
      <div class="demo-spacing-0 m-1 d-flex justify-content-between align-items-center"
           dir="rtl"
      >
        <h3 v-if="$route.name === 'show-accounts'">
          لیست زیر مجموعه ها
        </h3>
        <template v-else>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
            <feather-icon icon="FilterIcon"/>
            فیلتر
          </b-button>
        </template>
      </div>

      <search-and-filter
        :is-active.sync="isActive"
        :options="columns"
        @filter="getData(1, perPage)"
        @reset="getData(1, perPage)"
      />

      <b-modal
        ref="msg-modal"
        cancel-variant="outline-secondary"
        ok-title="ارسال پیام"
        cancel-title="لغو"
        centered
        title="پیام جدید"
        @ok.prevent="sendMessage"
        v-model="messageModal"
        size="lg"
      >
        <b-overlay :show="loading">
          <b-form>
            <b-form-group label="عنوان پیام">
              <b-form-input
                type="text"
                placeholder="عنوان"
                v-model="message.title"
              />
            </b-form-group>
            <b-form-group label="متن پیام">
              <b-form-textarea placeholder="متن پیام" v-model="message.body"/>
            </b-form-group>
          </b-form>
        </b-overlay>
      </b-modal>

      <div class="relative-over-x">
        <b-table
          ref="refUserListTable"
          :items="items"
          :small="true"
          responsive
          :fields="columns"
          primary-key="id"
          show-empty
          empty-text="اطلاعاتی یافت نشد"
          style="white-space: nowrap; min-height : 235px"
          @sort-changed="sort($event)"
          no-local-sort

        >

          <template #cell(type)="data">
            <b-avatar
              size="22"
              class="my-50"
              :src="data.item.in_token.icon"
              v-tooltip="data.item.in_token.name"
              :variant="`light-info`"
            />
            <feather-icon icon="ArrowLeftIcon"/>
            <b-avatar
              size="22"
              class="my-50"
              :src="data.item.out_token.icon"
              v-tooltip="data.item.out_token.name"
              :variant="`light-info`"
            />
          </template>

          <template #cell(inToken_symbol)="data">
                        <span dir="ltr">
                            {{ data.item.in_token.symbol }}
                        </span>
          </template>

          <template #cell(outToken_symbol)="data">
                        <span dir="ltr">
                            {{ data.item.out_token.symbol }}
                        </span>
          </template>

          <template #cell(chain_symbol)="data">
                        <span dir="ltr">
                            {{ data.item.chain.symbol }}
                        </span>
          </template>



          <template #cell(in_token_amount)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.in_amount) }} {{ data.item.in_token.symbol }}
                        </span>
          </template>


          <template #cell(out_token_amount)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.out_amount) }} {{ data.item.out_token.symbol }}
                        </span>
          </template>

          <template #cell(profit)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.profit) }} {{ data.item.profit_asset }}
                        </span>
          </template>

          <template #cell(created_at)="data">
                        <span dir="ltr">
                            {{ $G2J(data.item.created_at) }}
                        </span>
          </template>


        </b-table>
      </div>

      <!-- pagination -->
      <div
        class="demo-spacing-0 d-flex justify-content-between m-1"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          align="left"
          @input="getData(currentPage,perPage)"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
        <div>
          <v-select
            id="perpage"
            v-model="perPage"
            dir="rtl"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
            style="min-width: 85px"
            @input="getData(1,perPage)"
          />
          <label for="perpage">تعداد در صفحه</label>
        </div>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination,
  BCard,
  BButton,
  BTable,
  // BDropdown,
  // BDropdownItem,
  // BBadge,
  BAvatar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BOverlay
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';
// import NotAllowed from "@/layouts/components/NotAllowed";

export default {
  name: "Swap",
  props: ['chain'],
  components: {
    // NotAllowed,
    SearchAndFilter,
    BPagination,
    BCard,
    BButton,
    BTable,
    // BDropdown,
    // BDropdownItem,
    // BBadge,
    vSelect,
    BAvatar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BOverlay
  },
  data: () => ({
    test: 0,
    pageLength: 3,
    dir: false,
    searchTerm: '',
    currentPage: 1,
    perPage: 10,
    rows: 200,
    userData: [],
    isActive: false,
    messageModal: false,
    perPageOptions: [5, 10, 20, 50, 100],
    items: [],
    loading: false,
    loading2: false,
    message: {
      id: '',
      title: '',
      body: ''
    },
    columns: [
      {
        label: 'ارز مبدا / ارز مقصد',
        key: 'type',
        sortable: false,
        selectOptions: []
      },
      {
        label: 'ارز مبدا',
        key: 'inToken_symbol',
        sortable: false,
        searchType: 'text',
      },
      {
        label: 'ارز مقصد',
        key: 'outToken_symbol',
        sortable: false,
        searchType: 'text',
      },
      {
        label: 'مقدار ارز مبدا',
        key: 'inToken_symbol',
        sortable: false,
      },
      {
        label: 'مقدار ارز مقصد',
        key: 'outToken_symbol',
        sortable: false,
      },
      {
        label: 'شبکه',
        key: 'chain_symbol',
        sortable: false,
        searchType: 'text'
      },

      {
        label: 'سود تراکنش',
        key: 'profit',
        sortable: false
      },
      {
        label: 'تاریخ',
        key: 'created_at',
        sortable: false,
        searchType: 'date'
      },
      {
        label: 'شناسه تراکنش',
        key: 'tx_id',
        searchType: 'text',
        sortable: false
      },
      {
        label: 'آدرس کیف پول',
        key: 'account',
        searchType: 'text',
        sortable: false
      },
    ],

    modalNativeCoin: false,
    modalNativeCoinInfo: {},
  }),
  computed: {
    tokenStatus() {
      const a = {
        ACTIVATED: 'success',
        DISABLED: 'danger',
        PENDING: 'warning',
        DISABLED_BY_API: 'danger',
      }
      return e => a[e];
    },
    tokenStatusLabel() {
      const a = {
        ACTIVATED: 'فعال',
        DISABLED: 'غیرفعال',
        PENDING: 'در حال بررسی',
        DISABLED_BY_API: 'غیر فعال (provider)',
      }
      return e => a[e];
    },
    // georgianToJallali(){
    //     return e => this.$jmoment(e, 'YYYY-MM-DDTHH:mm:ss').format('dddd jD jMMMM jYYYY')
    // }
  },
  methods: {
    activeOrdersPage(id) {
      this.push('withdraw-orders', id)
    },
    orderHistoryPage(id) {
      this.push('wallet-transactions', id)
    },
    push(to, id) {
      this.$router.push({name: to, query: {email: id}})
    },
    openMessageModal(e) {
      this.message.id = e
      this.messageModal = true
    },


    sort(e) {

      let sort = this.$toSnakeCase(e.sortBy)
      // let sort = e.sortBy
      let sorting = e.sortDesc ? 'DESC' : 'ASC'

      this.$router.push({
        query: {
          ...this.$route.query,
          orderBy: sort,
          sorting: sorting
        }
      })

      this.getData(1, this.perPage)

    },
    async getData(page, perPage) {
      this.state.loading = true
      let address = '/swaps'

      let queryParams = {
        size: perPage,
        page: page,
        ...this.$route.query,
      }

      if (!this.$route.query['orderBy']) {
        queryParams['orderBy'] = 'created_at'
        queryParams['orderByType'] = 'desc'
      }


      const res = await this.$axios(
        address,
        {
          params: queryParams
        }
      )
      this.state.loading = false
      this.items = res.data.data
      this.currentPage = res.data.meta.current_page
      this.rows = res.data.meta.total
    },


    async downloadExcel() {
      await this.$axios(
        {
          url: '/users',
          method: 'GET',
          responseType: 'blob', // important
          params: {
            export: 'excel',
            ...this.$route.query
          }
        }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.$jmoment().format(this.$dateFormat['faDateTime']) + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    }

  },
  mounted() {
    this.getData(1, this.perPage)
  },

}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
